import React, { Component } from "react";
import {
    Input,
    PageHeader,
    Select,
    Form,
    InputNumber,
    DatePicker,
    Switch,
    Upload,
    Button,
    Skeleton,
} from 'antd';
// import { moveToPage } from "../../navigation/navigationService";
import { connect } from "react-redux";
// import moment from 'moment'
import DynamicFormGenerator from '../../components/DynamicFormGenerator'
import DynamicFormEntryGenerator from '../../components/DynamicFormEntryGenerator'
import { getDraftFormDetail, saveExistingAsDraft, submitForm } from '../../services/api'
import { goBack } from 'connected-react-router';


class ViewDraftFormPage extends Component {

    state = {
        formData: undefined,
        isError: false,
    }


    componentDidMount = async () => {

        const newParam = this.props.router.location.state

        const {
            key: id, form: formType
        } = newParam

        const form = await getDraftFormDetail(formType, id)
        if (form === null) {
            this.setState({ isError: true })
        } else {
            this.setState({ formData: form })
        }
    }


    render() {

        const newParam = this.props.router.location.state

        const {
            key: id, form: formType
        } = newParam

        return (
            this.state.isError ? (
                <div>
                    <h1>Error fetching form</h1>
                    <a onClick={() => {
                        this.props.dispatch(goBack())
                    }}>Go back</a>
                </div>
            ) : this.state.formData ? (
                <DynamicFormEntryGenerator
                    formData={this.state.formData}
                    title={`${this.state.formData.data.name} Form`}
                    onSaveAsDraft={values => {
                        const submitted_for__username = values.fields && values.fields['submitted-for'] && values.fields['submitted-for'].value

                        console.log(values)
                        saveExistingAsDraft(values, submitted_for__username, formType, values.id)
                    }}
                    onSubmit={values => {
                        const submitted_for__username = values.fields && values.fields['submitted-for'] && values.fields['submitted-for'].value

                        submitForm(values, submitted_for__username, formType)

                    }}
                />
            ) :
                    (
                        <div>
                            <h1>Loading...</h1>
                            <Skeleton active />
                        </div>
                    )

        )
    }
}



const mapStateToProps = (state) => ({
    router: state.router
});

export default connect(mapStateToProps)(ViewDraftFormPage);