import { goBack } from 'connected-react-router';
import { config } from '../../config'
import {
    message
} from 'antd'
import { TYPES_OF_FORMS } from '../../constants';
//import ViewDraft from '../../pages/ViewDraftFormPage/ViewDraftFormPage'


const api = config.api;
const headers = {
    'Accept': '*/*',
    'Content-Type': 'application/json; charset=utf-8'
}

export const getToken = () => {
    return localStorage.getItem('token')
}

export const login = async (username = 'admin1', password = 'admin@eforms@1') => {

    const body = {
        username, password
    }

    // console.log('from api')
    const apiToken = await fetch(`${api}/user/login/`, {
        headers,
        body: JSON.stringify(body),
        method: "POST"
    })
        .then(data => data.json())
        .then(data => data.token)

    localStorage.setItem('token', apiToken)

    return apiToken
}

export const logout = async () => {
    localStorage.removeItem('token')
}

export const getManpowerForm = async () => {

    const token = await getToken()

    console.log(token)

    const form = await fetch(`${api}/form/generator/manpower/`, {
        headers: {
            ...headers,
            'Authorization': `Token ${token}`
        },
        "method": "GET"
    })
        .then(resp => resp.json())

    return form
}

export const getCommissionForm = async () => {

    const token = await getToken()

    console.log(token)

    const form = await fetch(`${api}/form/generator/commission-requisition/`, {
        headers: {
            ...headers,
            'Authorization': `Token ${token}`
        },
        "method": "GET"
    })
        .then(resp => resp.json())

    return form
}

export const getLeaveForm = async () => {

    const token = await getToken()

    console.log(token)

    const form = await fetch(`${api}/form/generator/leave/`, {
        headers: {
            ...headers,
            'Authorization': `Token ${token}`
        },
        "method": "GET"
    })
        .then(resp => resp.json())
    return form
}

export const getTrainingForm = async () => {

    const token = await getToken()

    console.log(token)

    const form = await fetch(`${api}/form/generator/training/`, {
        headers: {
            ...headers,
            'Authorization': `Token ${token}`
        },
        "method": "GET"
    })
        .then(resp => resp.json())

    return form
}

export const getClaimForm = async () => {

    const token = await getToken()

    console.log(token)

    const form = await fetch(`${api}/form/generator/claim/`, {
        headers: {
            ...headers,
            'Authorization': `Token ${token}`
        },
        "method": "GET"
    })
        .then(resp => resp.json())

    return form
}

export const getCreditForm = async () => {

    const token = await getToken()

    console.log(token)

    const form = await fetch(`${api}/form/generator/credit-limit-request/`, {
        headers: {
            ...headers,
            'Authorization': `Token ${token}`
        },
        "method": "GET"
    })
        .then(resp => resp.json())

    return form
}

export const getLoanForm = async () => {

    const token = await getToken()

    console.log(token)

    const form = await fetch(`${api}/form/generator/loanrentdemo/`, {
        headers: {
            ...headers,
            'Authorization': `Token ${token}`
        },
        "method": "GET"
    })
        .then(resp => resp.json())

    return form
}

export const getPurchaseForm = async () => {

    const token = await getToken()

    console.log(token)

    const form = await fetch(`${api}/form/generator/purchase/`, {
        headers: {
            ...headers,
            'Authorization': `Token ${token}`
        },
        "method": "GET"
    })
        .then(resp => resp.json())

    return form
}
export const getCompute = async (data, endPoint) => {

    const token = await getToken()

    console.log(token)

    const form = await fetch(`${api}/${endPoint}`, {
        headers: {
            ...headers,
            'Authorization': `Token ${token}`
        },
        body: JSON.stringify({
            data: data,
        }),
        "method": "POST"
    })
        .then(resp => resp.json())

    return form
}

//Travel, Purchase, and Equipment API still missing

/** 
 * for POST method on save as NEW draft button
 * ${form} - pass form type parameter from FE to api 
*/
export const saveNewAsDraft = async (data, formType) => {
    console.log('saveAsDraft...')

    if (!Object.values(TYPES_OF_FORMS).includes(formType)) {
        message.error(`Invalid formType. Must be one of ${TYPES_OF_FORMS}`)
        return
    }

    const token = await getToken()

    console.log(token)

    return fetch(`${api}/form/entries/${formType}/`, {
        headers: {
            ...headers,
            'Authorization': `Token ${token}`
        },
        body: JSON.stringify({
            data: data
        }),
        "method": "POST"
    })
        .then(resp => resp.json())
        .then(data => {
            message.success(`Saved draft! Id ${data.id}`)
            console.log('...saveAsDraft')
            // dispatch(goBack())
            return data
        })
}


export const saveExistingAsDraft = async (data, submitted_for_username, formType, id) => {

    const token = await getToken()

    console.log(token)

    return fetch(`${api}/form/entries/${formType}/${id}/`, {
        headers: {
            ...headers,
            'Authorization': `Token ${token}`
        },
        body: JSON.stringify({
            submitted_for_username: submitted_for_username,
            data: data
        }),
        "method": "PATCH"
    })
        .then(async resp => {
            if (resp.status !== 200) {
                const data = await resp.json()
                throw data
            }
            return resp.json()
        })
        .then(data => {
            message.success(`Saved draft! Id ${data.id}`)

            console.log('...saveExistingAsDraft')
            // dispatch(goBack())
            return data
        })
        .catch(err => {
            message.error(JSON.stringify(err))
        })
}


export const submitForm = async (data, submitted_for_username, formType) => {
    console.log('submitForm...')

    if (!Object.values(TYPES_OF_FORMS).includes(formType)) {
        message.error(`Invalid formType. Must be one of ${TYPES_OF_FORMS}`)
        return
    }

    const token = await getToken()

    console.log(token)

    return fetch(`${api}/form/entries/${formType}/`, {
        headers: {
            ...headers,
            'Authorization': `Token ${token}`
        },
        body: JSON.stringify({
            data: data,
            submitted_for_username,
        }),
        "method": "POST"
    })
        .then(resp => resp.json())
        .then(data => {
            console.log('...submitForm')
            message.success('Form submitted')
            // dispatch(goBack())
            return data
        })
}
export const getDraftForms = async () => {

    const token = await getToken()

    console.log(token)

    const form = await fetch(`${api}/form/entries/?status__in=draft&page_size=10&search=&submitted_for__username=`, {
        headers: {
            ...headers,
            'Authorization': `Token ${token}`
        },
        "method": "GET"
    })
        .then(resp => resp.json())

    return form
}


export const getDraftFormDetail = async (formType, id) => {

    const token = await getToken()

    console.log(token)

    const form = await fetch(`${api}/form/entries/${formType}/${id}`, {
        headers: {
            ...headers,
            'Authorization': `Token ${token}`
        },
        "method": "GET"
    })
        .then(async resp => {
            if (resp.status !== 200) {
                const data = await resp.json()
                throw data
            }
            return resp.json()
        })
        .catch(err => {
            message.error(`Error getting draft: ${JSON.stringify(err)}`)
            return null
        })

    return form
}


export const getPastAction = async () => {

    const token = await getToken()

    console.log(token)

    const form = await fetch(`${api}/form/entries/action/?status__in!=pending,waiting,draft&page_size=5&search=&submitted_for__username=`, {
        headers: {
            ...headers,
            'Authorization': `Token ${token}`
        },
        "method": "GET"
    })
        .then(resp => resp.json())

    return form
}

export const getPastSubmissions = async () => {

    const token = await getToken()

    console.log(token)

    const form = await fetch(`${api}/form/entries/?status__in!=pending,approved,draft&page_size=5&search=&submitted_for__username=`, {
        headers: {
            ...headers,
            'Authorization': `Token ${token}`
        },
        "method": "GET"
    })
        .then(resp => resp.json())

    return form
}

export const getPendingForActions = async () => {

    const token = await getToken()

    console.log(token)

    const form = await fetch(`${api}/form/entries/action/?status__in=pending&page_size=5&search=&submitted_for__username=`, {
        headers: {
            ...headers,
            'Authorization': `Token ${token}`
        },
        "method": "GET"
    })
        .then(resp => resp.json())

    return form
}


export const getPendingForSubmissions = async () => {

    const token = await getToken()

    console.log(token)

    const form = await fetch(`${api}/form/entries/?status__in=pending,approved&page_size=5&search=&submitted_for__username=`, {
        headers: {
            ...headers,
            'Authorization': `Token ${token}`
        },
        "method": "GET"
    })
        .then(resp => resp.json())

    return form
}

export const getAccount = async () => {

    const token = await getToken()

    console.log(token)

    const form = await fetch(`${api}/account/me/`, {
        headers: {
            ...headers,
            'Authorization': `Token ${token}`
        },
        "method": "GET"
    })
        .then(resp => resp.json())

    return form
}

export const ViewFormHome = async () => {

    const token = await getToken()

    console.log(token)

    const form = await fetch(`${api}/form/entries/action/manpower/307/`, {
        headers: {
            ...headers,
            'Authorization': `Token ${token}`
        },
        "method": "GET"
    })
        .then(resp => resp.json())

    return form
}

export const dashboardPastSubmission = async () => {

    const token = await getToken()

    console.log(token)

    const form = await fetch(`${api}/form/entries/?status__in!=pending,approved,draft&page_size=5&search=&submitted_for__username=`, {
        headers: {
            ...headers,
            'Authorization': `Token ${token}`
        },
        "method": "GET"
    })
        .then(resp => resp.json())

    return form
}

//Travel, Purchase, and Equipment API still missing 
