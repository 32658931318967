import React from "react";

import { Form, Input, Button, Checkbox } from "antd";
import Icon from '@ant-design/icons';
import {
    CopyOutlined
} from '@ant-design/icons'

class LoginForm extends React.Component {

    state = {
        username: "",
        password: "",
        isChecked: false
    };

    componentDidMount = () => {
        if (localStorage.checkbox && localStorage.username !== "") {
            this.setState({
                isChecked: true,
                username: localStorage.username,
                password: localStorage.password
            })
        }
    }

    render() {
        return (
            <Form className="login-form">
                <CopyOutlined
                    style={{
                        fontSize: 250,
                        marginBottom: 50,
                    }}
                />
                <Form.Item style={{ marginBottom: "15px" }}>
                    <Input
                        prefix={
                            <Icon
                                type="user"
                                style={{ color: "rgba(0,0,0,.25)" }}
                            />
                        }
                        placeholder="Username"
                        value={this.state.username}
                        onChange={e => this.setState({ username: e.target.value })}
                    />
                </Form.Item>
                <Form.Item style={{ marginBottom: "15px" }}>
                    <Input
                        prefix={
                            <Icon
                                type="lock"
                                style={{ color: "rgba(0,0,0,.25)" }}
                            />
                        }
                        type="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={e =>
                            this.setState({ password: e.target.value })
                        }
                    />
                </Form.Item>
                <Form.Item>
                    <Checkbox
                        type="checkbox"
                        onChange={e => {
                            this.setState({ isChecked: e.target.checked }, 
                                // () =>{ console.log("checked: ", this.state.isChecked) }
                            )
                        }}
                    >
                        Remember Me
                    </Checkbox>
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        style={{
                            width: '100%'
                        }}
                        onClick={() =>
                            this.props.onLoginPress(
                                this.state.username,
                                this.state.password,
                                this.state.isChecked
                            )
                        }
                    >
                        LOG IN
                </Button>
                </Form.Item>

            </Form>
        )
    }
}

export default LoginForm;