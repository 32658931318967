import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'

import { user } from './user';
import { voucher } from "./voucher";

export const createRootReducer = (history) => combineReducers({
  user,
  voucher,
  router: connectRouter(history)
  // ... // rest of your reducers
})