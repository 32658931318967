import React from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router";
import asyncComponent from "../components/AsyncComponent";

import LoginPage from "../pages/LoginPage";
import _MainPage from "../pages/_MainPage";

// submit new form pages
import SubmitNew from "../pages/SubmitNewForm/SubmitNew";
import ManpowerFormPage from "../pages/SubmitNewForm/ManpowerFormPage";
import CommissionFormPage from "../pages/SubmitNewForm/CommissionFormPage/CommissionFormPage";
import LeaveFormPage from "../pages/SubmitNewForm/LeaveFormPage/LeaveFormPage";
import CreditFormPage from "../pages/SubmitNewForm/CreditFormPage/CreditFormPage";
import ClaimFormPage from "../pages/SubmitNewForm/ClaimFormPage/ClaimFormPage";
import LoanFormPage from "../pages/SubmitNewForm/LoanFormPage/LoanFormPage";
import TrainingFormPage from "../pages/SubmitNewForm/TrainingFormPage/TrainingFormPage";
import PurchaseFormPage from '../pages/SubmitNewForm/PurchaseFormPage';

import DraftFormPage from "../pages/DraftFormPage/DraftFormPage";
import ViewDraftFormPage from "../pages/ViewDraftFormPage/ViewDraftFormPage";
import HistoryPage from "../pages/HistoryPage/HistoryPage";
import HomePage from "../pages/HomePage/HomePage";
import ProfilePage from '../pages/ProfilePage/ProfilePage'
import PendingPage from '../pages/PendingPage/PendingPage';


const AsyncNotFoundPage = asyncComponent(() => import("../pages/NotFoundPage"));

// const mainRoute = [
//     "/",

// ]

const MainRoutes = props => {

    return (
        <Switch>

            <Route
                exact
                path={"/"}
                render={routeProps => (
                    <_MainPage  {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/login"}
                render={routeProps => (
                    <LoginPage  {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/home"}
                render={routeProps => (
                    <HomePage  {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/submit-new"}
                render={routeProps => (
                    <SubmitNew  {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/submit-new/commission-requisition"}
                render={routeProps => (
                    <CommissionFormPage  {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/submit-new/manpower"}
                render={routeProps => (
                    <ManpowerFormPage  {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/submit-new/claim"}
                render={routeProps => (
                    <ClaimFormPage  {...props} {...routeProps} />
                )}
            />
            <Route
                exact
                path={"/submit-new/credit-limit-request"}
                render={routeProps => (
                    <CreditFormPage  {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/submit-new/leave"}
                render={routeProps => (
                    <LeaveFormPage  {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/submit-new/purchase"}
                render={routeProps => (
                    <PurchaseFormPage  {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/submit-new/loanrentdemo"}
                render={routeProps => (
                    <LoanFormPage  {...props} {...routeProps} />
                )}
            />


            <Route
                exact
                path={"/submit-new/training"}
                render={routeProps => (
                    <TrainingFormPage  {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/history"}
                render={routeProps => (
                    <HistoryPage {...props}{...routeProps} />
                )}
            />

            <Route
                exact
                path={"/pending"}
                render={routeProps => (
                    <PendingPage  {...props} {...routeProps} />
                )}
            />

            <Route
                exact
                path={"/draft"}
                render={routeProps => (
                    <DraftFormPage {...props}{...routeProps} />
                )}
            />

            <Route
                exact
                path={"/draft/viewdraft/"}
                render={routeProps => (
                    <ViewDraftFormPage {...props}{...routeProps} />
                )}
            />

            <Route
                exact
                path={"/profile"}
                render={routeProps => (
                    <ProfilePage  {...props} {...routeProps} />
                )}
            />

            <Route component={AsyncNotFoundPage} />

        </Switch>
    )
}

export default connect(null)(MainRoutes);