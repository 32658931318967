
export const voucher = (state = VOUCHER_INITIAL_STATE, action) => {

    switch (action.type) {
        case "GET_ALL_MERCHANT_VOUCHERS": {

            let newObject = Object.assign({},state);

            let vouchers = action.vouchers;

            vouchers.map(voucher => {
                newObject.byId[voucher.mvid] = voucher
                if(!newObject.allIds.includes(voucher.mvid)) newObject.allIds.push(voucher.mvid)
                return null
            })

            return newObject


        }

        // case "GET_PROMO_CODE": {
            
        //     let newObject = Object.assign({},state);

        //     let promocode = action.promocode;

        //     newObject.byId[promocode.pid] = promocode

        //     return newObject
        // }


        case "ADD_MERCHANT_VOUCHER": {
            let newObject = Object.assign({},state);

            let merchantVoucher = action.merchantVoucher;
            

            newObject.byId[merchantVoucher.mvid] = merchantVoucher;
            if(!newObject.allIds.includes(merchantVoucher.mvid)) newObject.allIds.push(merchantVoucher.mvid);

            return newObject
        }

        case "EDIT_MERCHANT_VOUCHER": {
            let newObject = Object.assign({},state);

            let merchantVoucher = action.merchantVoucher;
            
            newObject.byId[merchantVoucher.mvid] = {...newObject.byId[merchantVoucher.mvid], ...merchantVoucher, image : `https://rush-power-v1.s3-ap-southeast-1.amazonaws.com${merchantVoucher.image}`}


            return newObject

        }

        case "BATCH_APPLY_MERCHANT_VOUCHER": {
            let newObject = Object.assign({},state);

            let voucher = action.voucher;      

            newObject.batchVouchers.push(voucher)

            return newObject
        }

        default: {
            return state;
        }
    }
}


const VOUCHER_INITIAL_STATE = {
    byId: {},
    allIds: [],
    batchVouchers: []
}

