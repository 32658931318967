import React, { Component } from "react";
import { 
    PageHeader, 
    // Button, 
    // Table, 
    // Empty 
} from 'antd';
// import { moveToPage } from "../../navigation/navigationService";
import { connect } from "react-redux";
// import moment from 'moment'

class MainPage extends Component {

    state = {

    }

    componentDidMount = () => {

    }

    render(){

        return(
            <div>

            <PageHeader
                title="Welcome to Dashboard"
            />

            </div>
        )
    }
}



const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(MainPage);