
const config = {
    project: process.REACT_APP_PROJECT_MODE,
    api: process.env.REACT_APP_FE_API_GATEWAY,
    version: process.env.REACT_APP_PROJECT_VERSION,
    firebaseConfig: JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG),
    timer: 1 * 60* 1000, // App Version
}

module.exports = {
    config
};
