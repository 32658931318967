//TODO
/**
 * Implement back the login (it is now commented for development purpose)
 */

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


import { Provider } from "react-redux";

import configureStore, { history } from './configureStore'

import {
    ConnectedRouter
} from "connected-react-router";
import {
    Switch,
    Route,
    Redirect
} from "react-router";

import firebase from "firebase/app";
import "firebase/messaging";
import "firebase/storage";

import LoginPage from "./pages/LoginPage";

import { config } from "./config";
import { getToken } from './services/api';


const store = configureStore({});


const ConnectedApp = ({ rootStore }) => {
    
    return (
        <Provider store={rootStore}>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route exact path="/login" component={LoginPage} />
                    <Route
                        path="/"
                        render={() => (
                            getToken() ? (
                                <App />
                            ) : (
                                    <Redirect to={{ pathname: "/login" }} />
                                )
                        )}
                    />
                </Switch>
            </ConnectedRouter>
            {/* </PersistGate> */}
        </Provider>
    );
};

ReactDOM.render(
    <ConnectedApp rootStore={store} />,
    document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
