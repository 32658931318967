import React, { Component } from "react";
import {
    Input,
    PageHeader,
    Select,
    Form,
    InputNumber,
    DatePicker,
    Switch,
    Upload,
    Button,
    Skeleton,
} from 'antd';
// import { moveToPage } from "../../navigation/navigationService";
import { connect } from "react-redux";
// import moment from 'moment'
import DynamicFormGenerator from '../../../components/DynamicFormGenerator';
import { getManpowerForm, saveNewAsDraft, submitForm } from '../../../services/api'
import { TYPES_OF_FORMS } from '../../../constants';
import { goBack } from 'connected-react-router';

class ManpowerFormPage extends Component {

    state = {

    }


    componentDidMount = async () => {


        const form = await getManpowerForm()

        this.setState({ formData: form })
    }


    render() {

        return (
            this.state.formData ? (
                <DynamicFormGenerator
                    formData={this.state.formData}
                    title='Manpower Form'
                    onSaveAsDraft={values => {
                        console.log('onsaveNewAsDraft', values)

                        saveNewAsDraft(values, TYPES_OF_FORMS.MANPOWER)
                        this.props.dispatch(goBack())
                    }}
                    onSubmit={values => {
                        console.log(`onSubmit`)
                        const submitted_for_username = values.fields && values.fields['submitted-for'] &&  values.fields['submitted-for'].value
                        

                        submitForm(values, submitted_for_username, TYPES_OF_FORMS.MANPOWER)
                        this.props.dispatch(goBack())
                    }}
                />
            ) :
                (
                    <div>
                        <h1>Loading...</h1>
                        <Skeleton active />
                    </div>
                )

        )
    }
}



const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(ManpowerFormPage);