import React from "react";
import { message } from "antd";
import { connect } from "react-redux";
// import { appStore } from "../../services/redux/reducers";

import {
    getToken,
    login
} from '../../services/api'

import {
    moveToDash, moveToPage,
    // moveToMenuSelection
} from "../../navigation/navigationService";
import Icon from '@ant-design/icons';

// import logo from '../../images/logo.png';
import LoginForm from './LoginForm';

class LoginPage extends React.Component {



    loginPressed = (email, password) => {
        login(email, password)
            .then(async apiToken => {
                if (apiToken) {
                    this.props.dispatch(moveToPage('/home'));
                    message.info('Login success')
                } else {
                    message.error('Login error')
                }
            })
    };

    
    componentDidMount = () => {
        const token = getToken()

        if (token) {
            this.props.dispatch(moveToPage('home'));
        }
    }

    render() {
        // console.log("Hi Login Page")

        window.props = this.props
        return (
            <div
                style={{
                    height: "100vh",
                    width: "100%",
                    display: "flex",
                    flex: 1,
                    alignItems: "center"
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        height: "100vh",
                        width: "100%",
                        backgroundImage: ``,
                        backgroundSize: "cover"
                    }}
                />

                <div
                    style={{
                        zIndex: 1,
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        justifyContent: "center"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center"
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: "white",
                                padding: "35px 30px 15px 30px",
                                borderRadius: "17px"
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center"
                                }}
                            >
                                <img
                                    // alt={'logo'}
                                    // src={logo}
                                    style={{ height: "180px" }}
                                />
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center"
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}
                                >
                                    <LoginForm
                                        {...this.props}
                                        onLoginPress={(email, password, isChecked) => {
                                            if (isChecked && email !== "") {
                                                localStorage.username = email
                                                localStorage.password = password
                                                localStorage.checkbox = isChecked
                                            }
                                            this.loginPressed(email, password)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    user: state.user,
});

export default connect(mapStateToProps)(LoginPage);