import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    FormOutlined,
    HomeOutlined,
    UnlockOutlined,
    EditOutlined,
    ClockCircleOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {
    Layout, Menu,
    // Breadcrumb 
} from 'antd';
import { moveToPage } from "../../navigation/navigationService";
import logo from "../../images/elm-logo.png";


const {
    Sider
} = Layout;
const { SubMenu } = Menu;

const MenuSidebar = props => {

    const dispatch = useDispatch();

    const [collapsed, setCollapsed] = useState(false)

    const onCollapse = collapsed => {
        setCollapsed(collapsed)
    };

    return (
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>

            <div onClick={e => dispatch(moveToPage("/"))}>
                <img
                    alt={'logo'}
                    src={logo}
                    style={{
                        height: 32,
                        margin: 16
                    }}
                />
            </div>

            <Menu theme="dark" defaultSelectedKeys={['0']} mode="inline">

                <Menu.Item key="Home" onClick={e => dispatch(moveToPage("/home"))}>
                    <span>
                        <HomeOutlined />
                        <span>Home</span>
                    </span>
                </Menu.Item>

                {/* <SubMenu
                    key="Submit New<" onClick={e => dispatch(moveToPage("/submit-new"))}
                    title={
                        <span>
                            <FormOutlined />
                            <span>Submit New</span>
                        </span>
                    }
                >
                    <Menu.Item key="Commission Requisition" onClick={e => dispatch(moveToPage("/submit-new/commission-requisition"))}>
                        <span>Commission</span>
                    </Menu.Item>
                    <Menu.Item key="Manpower" onClick={e => dispatch(moveToPage("/submit-new/manpower"))}>
                        <span>Manpower</span>
                    </Menu.Item>
                    <Menu.Item key="Claim" onClick={e => dispatch(moveToPage("/submit-new/claim"))}>
                        <span>Claim</span>
                    </Menu.Item>
                    <Menu.Item key="Credit" onClick={e => dispatch(moveToPage("/submit-new/credit-limit-request"))}>
                        <span>Credit</span>
                    </Menu.Item>
                    <Menu.Item key="Leave" onClick={e => dispatch(moveToPage("/submit-new/leave"))}>
                        <span>Leave</span>
                    </Menu.Item>
                    <Menu.Item key="Loan" onClick={e => dispatch(moveToPage("/submit-new/loanrentdemo"))}>
                        <span>Loan</span>
                    </Menu.Item>
                    <Menu.Item key="Training" onClick={e => dispatch(moveToPage("/submit-new/training"))}>
                        <span>Training</span>
                    </Menu.Item>
                    <Menu.Item key="Purchase" onClick={e => dispatch(moveToPage("/purchase"))}>
                        <span>Purchase</span>
                    </Menu.Item>

                </SubMenu> */}

                <Menu.Item key="Submit New" onClick={e => dispatch(moveToPage("/submit-new"))}>
                    <span>
                        <FormOutlined />
                        <span>Submit New</span>
                    </span>
                </Menu.Item>

                <Menu.Item key="History" onClick={e => dispatch(moveToPage("/history"))}>
                    <span>
                        <UnlockOutlined />
                        <span>History</span>
                    </span>
                </Menu.Item>

                <Menu.Item key="Pending" onClick={e => dispatch(moveToPage("/pending"))}>
                    <span>
                        <ClockCircleOutlined />
                        <span>Pending</span>
                    </span>
                </Menu.Item>

                <Menu.Item key="Draft" onClick={e => dispatch(moveToPage("/draft"))}>
                    <EditOutlined />
                    <span>Draft</span>
                </Menu.Item>
                <Menu.Item key="Profile" onClick={e => dispatch(moveToPage("/profile"))}>
                    <UserOutlined />
                    <span>Profile</span>
                </Menu.Item>
            </Menu>
        </Sider>
    )

}

export default MenuSidebar 