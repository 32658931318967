import React, { Component } from 'react';
import {
    Table,
    Input,
    PageHeader,
    Select,
    Form,
    InputNumber,
    DatePicker,
    Switch,
    Upload,
    Button,
    Skeleton
} from 'antd';
// import { moveToPage } from "../../navigation/navigationService";
import { connect } from "react-redux";
// import moment from 'moment';
import { getPastAction } from '../../services/api';
import { getPastSubmissions } from '../../services/api';
import DynamicFormGenerator from '../../components/DynamicFormGenerator';
import { AccessAnalyzer } from 'aws-sdk';


const formating = (items) => {
    const moment = require('moment');
    let D = moment(items);
    D = D.format('LLL');
    return D;
}

const submitting = (items) => {
    const fullName = items.first_name + " " + items.last_name;
    return fullName;
}

class HistoryPage extends Component {

    state = {
        actions: undefined,
        submissions: undefined
    }

    componentDidMount = async () => {

        const form = await getPastAction()

        const Act = form.results && form.results.map(items => {
            const ac = {};
            ac.key = items.id;
            ac.serial_number = items.serial_number;
            ac.form = items.form;
            ac.created = formating(items.created);
            ac.modified = formating(items.modified);
            ac.submitted_by = submitting(items.submitted_by);
            ac.submitted_for = submitting(items.submitted_for);

            return ac;
        });

        this.setState({ actions: Act })

        const form1 = await getPastSubmissions()

        const Sub = form1.results.map(items => {
            const sb = {};
            sb.key = items.id;
            sb.serial_number = items.serial_number;
            sb.form = items.form;
            sb.created = formating(items.created);
            sb.modified = formating(items.modified);
            sb.submitted_by = submitting(items.submitted_by);
            sb.submitted_for = submitting(items.submitted_for);

            return sb;
        });

        this.setState({ submissions: Sub })

    }

    render() {
        console.log(this.state.actions);

        return (

            <div>

                <PageHeader title="History" />
                {

                    this.state.actions && this.state.submissions ? (
                        <div style={{textAlign: 'left'}}>
                            <h3>Past Actions</h3>
                            <Table
                                columns={columns}
                                dataSource={this.state.actions}
                                pagination={{ pageSize: 5 }}
                                scroll={{ x: columns.length * 100 }}
                            />

                            <h3>Past Submissions</h3>
                            <Table
                                columns={columns}
                                dataSource={this.state.submissions}
                                pagination={{ pageSize: 5 }}
                                scroll={{ x: columns.length * 100 }}
                            />
                        </div>

                    ) :
                        (
                            <div>
                                <h1>Loading...</h1>
                                <Skeleton active />
                            </div>
                        )
                }

            </div>
        )
    }
}

const columns = [
    {
        title: 'REFERENCE NO.',
        dataIndex: 'serial_number',
        key: 'serial_number',
    },
    {
        title: 'TYPE',
        dataIndex: 'form',
        key: 'form',
    },
    {
        title: 'DATE SUBMITTED',
        dataIndex: 'created',
        key: 'created',
    },
    {
        title: 'LAST UPDATED',
        dataIndex: 'modified',
        key: 'modified',
    },
    {
        title: 'SUBMITTED BY',
        dataIndex: 'submitted_by',
        key: 'submitted_by ',
    },
    {
        title: 'SUBMITTED FOR',
        dataIndex: 'submitted_for',
        key: 'submitted_for',
    },
    {
        title: '',
        dataIndex: 'view',
        key: 'view',
        render: () => (
            <a>View Form</a>
        )
    },
]

const mapStateToProps = (state) => ({
    // for future if needed
})

export default connect(mapStateToProps)(HistoryPage);