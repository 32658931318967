import React, { Component } from 'react';
import {
    Input,
    PageHeader,
    Select,
    Form,
    InputNumber,
    DatePicker,
    Switch,
    Upload,
    Button,
    Skeleton
} from 'antd';
// import { moveToPage } from "../../navigation/navigationService";
import { connect } from "react-redux";
// import moment from 'moment';
import { getManpowerForm, getClaimForm, getCompute, saveNewAsDraft, submitForm } from '../../../services/api'
import { TYPES_OF_FORMS } from '../../../constants';
import DynamicFormGenerator from '../../../components/DynamicFormGenerator';
import { goBack } from 'connected-react-router';

/**Setup */
const formType = TYPES_OF_FORMS.CLAIM

class ClaimFormPage extends Component {

    state = {
    }

    componentDidMount = async () => {

        const form = await getClaimForm()

        this.setState({ formData: form })
    }

    getFormattedComputeForm = (computeForm) => {

        const tally_fields = Object.keys(computeForm.tally_fields).map(key => {
            const value = computeForm.tally_fields[key].value
            const obj = this.state.formData.tally_fields.find(f => f.slug === key)
            return {
                ...obj,
                value,
                slug: key
            }
        })

        const form = JSON.parse(JSON.stringify(this.state.formData))
        form.tally_fields = tally_fields

        return form
    }


    render() {

        return (

            this.state.formData ? (
                <DynamicFormGenerator
                    loopedFunction={async (data) => {
                        const isFormUsingComputer = this.state.formData.computer

                        if (isFormUsingComputer) {
                            const endPoint = this.state.formData.computer
                            const computeForm = await getCompute(data, endPoint)

                            const form = this.getFormattedComputeForm(computeForm)

                            this.setState({
                                formData: form
                            })
                        }
                    }}
                    formData={this.state.formData}
                    title={`${this.state.formData.name} Form`}
                    onSaveAsDraft={values => {
                        console.log('onsaveNewAsDraft', values)

                        saveNewAsDraft(values, formType)
                        this.props.dispatch(goBack())
                    }}
                    onSubmit={values => {
                        console.log(`onSubmit`)
                        const submitted_for_username = values.fields && values.fields['submitted-for'] && values.fields['submitted-for'].value


                        submitForm(values, submitted_for_username, formType)
                        this.props.dispatch(goBack())
                    }}
                />
            ) :
                (
                    <div>
                        <h1>Loading...</h1>
                        <Skeleton active />
                    </div>
                )
        )
    }
}

const mapStateToProps = (state) => ({
    // for future if needed
})

export default connect(mapStateToProps)(ClaimFormPage);