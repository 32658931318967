
import React, { Component } from 'react';
import {
    Table,
    Input,
    PageHeader,
    Select,
    Form,
    InputNumber,
    DatePicker,
    Switch,
    Upload,
    Button,
    Skeleton,
    Layout,
    Descriptions,
    message
} from 'antd';
// import { moveToPage } from "../../navigation/navigationService";
import { connect } from "react-redux";
// import moment from 'moment';
import { getAccount, logout } from '../../services/api';
import * as navigationService from '../../navigation/navigationService';
import DynamicFormGenerator from '../../components/DynamicFormGenerator';
import { FSx } from 'aws-sdk';


const formating = (items) => {
    const moment = require('moment');
    let D = moment(items);
    D = D.format('LLL');
    return D;
}

const submitting = (items) => {
    const fullName = items.first_name + " " + items.last_name;
    return fullName;
}



class ProfilePage extends Component {

    state = {
        user: undefined
    }

    componentDidMount = async () => {
        const account = await getAccount()

        this.setState({ user: account })

        console.log(account)
    }

    render() {


        return (
            <div>
                <PageHeader title="Profile" />

                <div style={{
                    display: "flex",
                    justifyContent: "center",
                }}>
                    <Descriptions
                        bordered={true}
                        column={1}
                        style={{
                            width: "70%",
                            justifyContent: "center",
                            backgroundColor: "#e9e9e9"
                        }}
                    >
                        <Descriptions.Item label='Username'>
                            {this.state.user && this.state.user.username}
                        </Descriptions.Item>
                        <Descriptions.Item label='Full Name'>
                            {this.state.user && `${this.state.user.first_name} ${this.state.user.last_name}`}
                        </Descriptions.Item>
                        <Descriptions.Item label='Company'>
                        </Descriptions.Item>
                        <Descriptions.Item label='Business Unit'>
                        </Descriptions.Item>
                        <Descriptions.Item label='Designation'>
                            <ol>
                                {
                                    this.state.user && this.state.user.roles && this.state.user.roles.map(role => {
                                        return <li>{role}</li>
                                    })
                                }
                            </ol>
                            {this.state.user && this.state.user.username}
                        </Descriptions.Item>
                        <Descriptions.Item label='Immediate Superior'>
                        </Descriptions.Item>
                        <Descriptions.Item label='Claim Budgets Immediate Subordinates'>
                        </Descriptions.Item>
                        <Descriptions.Item label='Family Relations'>
                            {this.state.user && this.state.user.family_relations}
                        </Descriptions.Item>
                    </Descriptions>
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Button
                        type="primary"
                        style={{
                            width: "200px",
                            marginTop: "5px"
                        }}
                        onClick={() => {
                            logout()
                            this.props.dispatch(navigationService.moveToPage('/login'));

                            message.info('Logout success')
                        }}
                    >
                        Logout
                    </Button>

                </div>
            </div>
        )

    }
}



const mapStateToProps = (state) => ({
    // for future if needed
})

export default connect(mapStateToProps)(ProfilePage);