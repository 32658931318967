import React, { Component } from 'react';
import {
    Table,
    Input,
    PageHeader,
    Select,
    Form,
    InputNumber,
    DatePicker,
    Switch,
    Upload,
    Button,
    Skeleton
} from 'antd';
import * as navigationService from "../../navigation/navigationService";
import { connect } from "react-redux";
// import moment from 'moment';
import { getDraftForms } from '../../services/api';
import DynamicFormGenerator from '../../components/DynamicFormGenerator';

const moment = require('moment');

const formating = (items) => {
    let D = moment(items);
    D = D.format('LLL');
    return D;
}

const submitting = (item) => {
    const fullName = `${item.first_name} ${item.last_name} (${item.username})`
    return fullName;
}

class DraftFormPage extends Component {

    state = {
        formData: undefined
    }

    componentDidMount = async () => {

        const form = await getDraftForms()
        console.log(form)
        const FormDate = form.results.map(items => {
            const fd = {};
            fd.key = items.id;
            fd.id = items.id;
            fd.form = items.form;
            fd.created = formating(items.created);
            fd.modified = formating(items.modified);
            fd.submitted_for = submitting(items.submitted_for);

            return fd;
        });


        this.setState({ formData: FormDate })


    }

    render() {

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'TYPE',
                dataIndex: 'form',
                key: 'form',
            },
            {
                title: 'DATA CREATED',
                dataIndex: 'created',
                key: 'created',
            },
            {
                title: 'LAST MODIFIED',
                dataIndex: 'modified',
                key: 'modified',
            },
            {
                title: 'SUBMITTED FOR',
                dataIndex: 'submitted_for',
                key: 'submitted_for',
            },
            {
                title: '',
                dataIndex: 'view',
                key: 'view',
                render: (a, b) => {
                    return (
                        <Button type="link" onClick={e => {
                            console.log(b)
                            const newParam = b;
                            this.props.dispatch(navigationService.moveToPage("/draft/viewdraft/", newParam))
                        }}>View Form</Button>
                    )


                }

            },
        ]

        return (
            <div>
                <PageHeader title="Drafts" />
                {


                    this.state.formData ? (

                        <Table columns={columns} dataSource={this.state.formData} />
                    ) :
                        (
                            <div>
                                <h1>Loading...</h1>
                                <Skeleton active />
                            </div>
                        )

                }
            </div>
        )
    }
}



const mapStateToProps = (state) => ({
    // for future if needed
})

export default connect(mapStateToProps)(DraftFormPage);