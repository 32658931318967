import * as ACTION_TYPE from '../action-type/user';

export const user = (state = {userList:[], coinList:[], byId: {}}, action) => {
    switch (action.type) {
        case ACTION_TYPE.USER_SIGN_IN: {
            let user = action.user;
            // console.log('user', user)
            return({
                ...state,
                ...user
            });
        }

        case "GET_ALL_USER": {
            let users = action.users;

            let newObject = Object.assign({},state)
            newObject.userList = users;

            return newObject;
        }

        default: {
            return state;
        }
    }
}

