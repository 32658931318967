import React, { Component } from 'react';
import {
    Input,
    PageHeader,
    Select,
    Form,
    InputNumber,
    DatePicker,
    Switch,
    Upload,
    Button,
    Skeleton
} from 'antd';
// import { moveToPage } from "../../navigation/navigationService";
import { connect } from "react-redux";
// import moment from 'moment';
import { getLoanForm } from '../../../services/api';
import DynamicFormGenerator from '../../../components/DynamicFormGenerator';


class LoanFormPage extends Component {

    state = {
    
    }

    componentDidMount = async () => {

        const form = await getLoanForm()

        this.setState({ formData: form })

        // console.log("form data: ", this.state.formData);
    }

    render() {

        return (

            this.state.formData ? (
                <DynamicFormGenerator 
                    formData={this.state.formData}
                    title="Loan Form"
                    onSubmit={values => {
                        console.log("values:", values)
                    }}
                />
            ) : 
                (
                    <div>
                        <h1>Loading...</h1>
                        <Skeleton active />
                    </div>
                )
        )
    }
}

const mapStateToProps = (state) => ({
    // for future if needed
})

export default connect(mapStateToProps)(LoanFormPage);