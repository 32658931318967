import React, { Component } from "react";
import './App.css';
import { connect } from "react-redux";

import { 
  Layout, 
  // Breadcrumb 
} from 'antd';
import {
  config
} from './config'
import MainRoutes from "./navigation/MainRoutes";

import MenuSidebar from './components/MenuSidebar'

const { 
  // Header, 
  Content, 
  Footer, 
} = Layout;

class App extends Component {

  render(){
    

    return (
      <div className="App">
        <Layout style={{ minHeight: '100vh' }}>
          <MenuSidebar/>
          <Layout>
            {/* <Header style={{ background: '#fff', padding: 0 }} /> */}
            <Content style={{ margin: '0 16px' }}>
                <div style={{marginTop: 10}}></div>
              {/* <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>User</Breadcrumb.Item>
                <Breadcrumb.Item>Bill</Breadcrumb.Item>
              </Breadcrumb> */}
              {/* <div style={{ marginTop: 16, padding: 24, background: '#fff', minHeight: 600 }}>Bill is a cat.</div> */}


              <MainRoutes {...this.props}/>

            </Content>  
            <Footer style={{ textAlign: 'center' }}>{`@2020 ELM eForms Web - Version ${config.version}`}</Footer>
          </Layout>
        </Layout>
      </div>
    );
  }

}

export default  connect(appStore => appStore)(App);
