import React, { Component } from "react";
import {
    Input,
    PageHeader,
    Select,
    Form,
    InputNumber,
    DatePicker,
    Switch,
    Upload,
    Button,
    message,
    Space,
    Modal,
    Table,
    Descriptions,
} from 'antd';
// import { moveToPage } from "../../navigation/navigationService";
import { connect } from "react-redux";
import moment from 'moment'
import { nanoid } from 'nanoid'
import { FIELD_TYPES } from '../../constants'
class DynamicFormGenerator extends Component {

    state = {

    }
    getField = (field, i) => {
        console.log(field.label)

        function getRules(field) {

            const required = !field.optional
            const fieldName = field.label

            const rules = [
                {
                    required: required,
                    message: `Please input ${fieldName}`,
                },
            ]

            return rules
        }


        const key = field.slug
        const props = {
            key: i,
            name: field.slug,
            label: field.label,
            rules: getRules(field),
            help: field.help,
            placeholder: field.label,
        }

        switch (field.type) {
            case FIELD_TYPES.text:
            case FIELD_TYPES.textinput:
            case FIELD_TYPES.hidden:
                return (
                    <Form.Item
                        {...props}
                    >
                        <Input
                            onChange={e => {
                                this.setState({
                                    [key]: e.target.value
                                })
                            }}
                        />
                    </Form.Item>
                )
            case FIELD_TYPES.integer:
            case FIELD_TYPES.float:
                return (
                    <Form.Item
                        {...props}
                    >
                        <InputNumber
                            style={{
                                width: '100%',
                            }}
                            onChange={v => {
                                this.setState({
                                    [key]: v
                                })
                            }}
                        />
                    </Form.Item>
                )
            case FIELD_TYPES.boolean:
                return (
                    <Form.Item
                        {...props}
                    >
                        <Switch
                            value={field.value}
                            onChange={v => {
                                this.setState({
                                    [key]: v
                                })
                            }} />
                    </Form.Item>
                )
            case FIELD_TYPES.file:
                return (
                    <Form.Item
                        {...props}
                    >
                        <Upload >
                            <Button >Click to Upload</Button>
                        </Upload>
                    </Form.Item>
                )
            case FIELD_TYPES.date:
                return (
                    <Form.Item
                        {...props}
                    >
                        <DatePicker
                            value={moment(field.value)}
                            onChange={v => {
                                this.setState({
                                    [key]: v.valueOf()
                                })
                            }} />
                    </Form.Item>
                )
            case FIELD_TYPES.dropdown:
                return (
                    <Form.Item
                        {...props}
                    >
                        <Select
                            value={field.value}
                            onChange={v => {
                                this.setState({
                                    [key]: v
                                })
                            }}
                            style={{
                                width: '100%',
                            }}

                        >
                            {
                                field.options && field.options.map(option => {
                                    return <Select.Option
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                )
        }
    }

    getTallyField = (field, i) => {

        function getRules(field) {

            const required = !field.optional
            const fieldName = field.label

            const rules = [
                {
                    required: required,
                    message: `Please input ${fieldName}`,
                },
            ]

            return rules
        }


        const key = field.slug
        const props = {
            key: i,
            name: field.slug,
            label: field.label,
            rules: getRules(field),
            help: field.help,
            placeholder: field.label,
        }

        const data = this.props.formData.tally_fields.find(_field => _field.slug === field.slug)
        const value = data && data.value

        console.log({ value })

        switch (field.type) {
            case FIELD_TYPES.text:
            case FIELD_TYPES.textinput:
            case FIELD_TYPES.hidden:
                return (
                    <>
                        <p>{field.label}</p>
                        <Input
                            value={value}
                            onChange={e => {
                                this.setState({
                                    [key]: e.target.value
                                })
                            }}
                        />
                    </>
                )
            case FIELD_TYPES.integer:
            case FIELD_TYPES.float:
                return (
                    <>
                        <p>{field.label}</p>
                        <InputNumber
                            value={field.value}
                            onChange={v => {
                                this.setState({
                                    [key]: v
                                })
                            }}
                        />
                    </>
                )
            case FIELD_TYPES.boolean:
                return (
                    <>
                        <p>{field.label}</p>
                        <Switch
                            value={field.value}
                            onChange={v => {
                                this.setState({
                                    [key]: v
                                })
                            }} />
                    </>
                )
            case FIELD_TYPES.file:
                return (
                    <>
                        <p>{field.label}</p>
                        <Upload >
                            <Button >Click to Upload</Button>
                        </Upload>
                    </>
                )
            case FIELD_TYPES.date:
                return (
                    <>
                        <p>{field.label}</p>
                        <DatePicker
                            value={moment(field.value)}
                            onChange={v => {
                                this.setState({
                                    [key]: v.valueOf()
                                })
                            }} />
                    </>
                )
            case FIELD_TYPES.dropdown:
                return (
                    <>
                        <p>{field.label}</p>
                        <Select
                            value={field.value}
                            onChange={v => {
                                this.setState({
                                    [key]: v
                                })
                            }}
                            style={{
                                width: '100%',
                            }}


                        >
                            {
                                field.options && field.options.map(option => {
                                    return <Select.Option
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </Select.Option>
                                })
                            }
                        </Select>
                    </>
                )
        }
    }
    getTableField = (field, i, rowKey) => {

        function getRules(field) {

            const required = !field.optional
            const fieldName = field.label

            const rules = [
                {
                    required: required,
                    message: `Please input ${fieldName}`,
                },
            ]

            return rules
        }


        const key = field.slug


        const props = {
            key: i,
            name: field.slug,
            label: null,
            rules: getRules(field),
            help: null,
            placeholder: field.label,
            // value: this.state.dataSource[rowKey][key],
        }


        switch (field.type) {
            case FIELD_TYPES.text:
            case FIELD_TYPES.textinput:
            case FIELD_TYPES.hidden:
                return (
                    <Input
                        onChange={e => {
                            const newDatasource = JSON.parse(JSON.stringify(this.state.dataSource))
                            newDatasource[rowKey] = {
                                ...newDatasource[rowKey],
                                [key]: e.target.value
                            }
                            console.log(newDatasource)
                            this.setState({
                                dataSource: newDatasource
                            })
                        }}
                    />
                )
            case FIELD_TYPES.integer:
            case FIELD_TYPES.float:
                return (
                    <InputNumber
                        onChange={v => {
                            const newDatasource = JSON.parse(JSON.stringify(this.state.dataSource))
                            newDatasource[rowKey] = {
                                ...newDatasource[rowKey],
                                [key]: v
                            }
                            this.setState({
                                dataSource: newDatasource
                            })
                        }}
                    />
                )
            case FIELD_TYPES.boolean:
                return (
                    <Switch
                        onChange={v => {
                            const newDatasource = JSON.parse(JSON.stringify(this.state.dataSource))
                            newDatasource[rowKey] = {
                                ...newDatasource[rowKey],
                                [key]: v
                            }
                            this.setState({
                                dataSource: newDatasource
                            })
                        }} />
                )
            case FIELD_TYPES.file:
                return (
                    <Form.Item
                        {...props}
                    >
                        <Upload >
                            <Button >Click to Upload</Button>
                        </Upload>
                    </Form.Item>
                )
            case FIELD_TYPES.date:
                return (
                    <DatePicker
                        onChange={v => {
                            const newDatasource = JSON.parse(JSON.stringify(this.state.dataSource))
                            newDatasource[rowKey] = {
                                ...newDatasource[rowKey],
                                [key]: v.valueOf()
                            }
                            this.setState({
                                dataSource: newDatasource
                            })
                        }} />
                )
            case FIELD_TYPES.dropdown:
                return (
                    <Select
                        style={{
                            width: '100%',
                        }}
                        onChange={v => {
                            const newDatasource = JSON.parse(JSON.stringify(this.state.dataSource))
                            newDatasource[rowKey] = {
                                ...newDatasource[rowKey],
                                [key]: v
                            }
                            this.setState({
                                dataSource: newDatasource
                            })
                        }}

                    >
                        {
                            field.options && field.options.map(option => {
                                return <Select.Option
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.label}
                                </Select.Option>
                            })
                        }
                    </Select>
                )
        }
    }


    componentDidMount = () => {

        if (!this.props
            || !this.props.loopedFunction) {
            return
        }

        this.timer = setInterval(() => {
            const draftForm = this.format()
            this.props.loopedFunction(draftForm)
        }, 3 * 1000)
    }

    componentWillUnmount = () => {
        clearInterval(this.timer)
    }

    format = () => {

        const draftForm = JSON.parse(JSON.stringify(this.props.formData))


        /**Fields */
        const fields = {}
        this.props.formData.fields.map(field => {

            const newValue = {
                label: field.label,
                type: field.type,
                extra: field.extra,
                value: this.state[field.slug] || ''
            }

            fields[field.slug] = newValue
        })
        draftForm.fields = fields

        /**Table fields */
        const table_fields = this.state.dataSource && Object.values(this.state.dataSource).map(data => {
            const row = {}
            this.props.formData.table_fields.map(field => {
                const newValue = {
                    value: data[field.slug] || '',
                    label: field.label,
                    type: field.type,
                    extra: field.extra,
                }

                row[field.slug] = newValue
            })
            return row
        })
        draftForm.table_fields = table_fields

        /**Tally fields */
        const tally_fields = {}
        this.props.formData.tally_fields.map(field => {

            const newValue = {
                label: field.label,
                type: field.type,
                extra: field.extra,
                value: this.state[field.slug] || ''
            }

            tally_fields[field.slug] = newValue
        })
        draftForm.tally_fields = tally_fields

        return draftForm
    }

    render() {


        const {
            formData,
            title,
            onSaveAsDraft,
            onSubmit,
        } = this.props
        const {
            fields = [],
            table_fields = [],
            tally_fields = [],
        } = formData

        window.props = this.props
        window.state = this.state

        const columns = [
            ...table_fields,
            {
                label: 'Delete',
                slug: null,
                fixed: 'right',
            }
        ].map((field, i) => {
            return {
                ...field,
                title: field.label,
                dataIndex: field.slug,
                render: (data, dataRow) => {
                    const key = dataRow.key
                    if (field.slug) {
                        return this.getTableField(field, i, key)
                    } else {
                        return <Button
                            onClick={() => {
                                const newDatasource = JSON.parse(JSON.stringify(this.state.dataSource))
                                delete newDatasource[key]
                                this.setState({ dataSource: newDatasource })
                            }}
                        >Delete</Button>
                    }
                }
            }
        })


        /**Setup table form's first row */
        if (!this.state.dataSource) {
            const rowObject = {
                key: nanoid()
            }
            table_fields.map(field => {
                rowObject[field.slug] = null
            })
            this.setState({
                dataSource: {
                    [rowObject.key]: rowObject
                }
            })
        }

        return (
            <div>

                <PageHeader
                    title={title}
                />
                <Form
                    onFinishFailed={err => {
                        const errorMessage = err.errorFields[0].errors[0]
                        message.error(errorMessage)
                        console.log({ err })
                    }}
                    labelCol={{
                        span: 5,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                >

                    {/* Fields */}
                    {
                        fields.map((field, i) => this.getField(field, i))
                    }

                    {/* Table fields */}{
                        table_fields.length && (
                            <div>

                                <Table
                                    dataSource={Object.values(this.state.dataSource || {})}
                                    columns={columns}
                                    scroll={{
                                        x: columns && columns.length * 150,
                                        y: 300
                                    }}
                                />
                                <Button
                                    type={'primary'}
                                    style={{
                                        width: '100%'
                                    }}
                                    onClick={() => {
                                        const rowObject = {
                                            key: nanoid()
                                        }
                                        table_fields.map(field => {
                                            rowObject[field.slug] = null
                                        })
                                        this.setState({
                                            dataSource: {
                                                ...this.state.dataSource,
                                                [rowObject.key]: rowObject
                                            }
                                        })
                                    }}
                                >
                                    Add Row
                            </Button>
                            </div>
                        ) || null
                    }

                    {/* Tally fields */}
                    {
                        tally_fields.map((field, i) => this.getTallyField(field, i))
                    }

                </Form>

                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        marginTop: 30,
                        justifyContent: 'space-around',
                    }}
                >

                    <Button type="secondary" key={'draft'}
                        onClick={() => {
                            const draftForm = this.format()

                            onSaveAsDraft(draftForm)
                        }}
                    >
                        Save as draft
                            </Button>

                    <Button type="primary" key={'next'}
                        onClick={() => {
                            Modal.confirm({
                                title: 'Submit form?',
                                content: (
                                    <li>
                                        {
                                            this.props.formData.approvers
                                            && this.props.formData.approvers.map((approver, i) => {
                                                return <ol>Approver {i + 1} - {approver.username}</ol>
                                            })
                                        }
                                        {
                                            this.props.formData.executors
                                            && this.props.formData.executors.map((executor, i) => {
                                                return <ol>Executor {i + 1} - {executor.username}</ol>
                                            })
                                        }
                                    </li>
                                ),
                                okButtonProps: { disabled: true },
                                onOk: () => {

                                    const values = this.state

                                    const fields = {}
                                    this.props.formData.fields.map(value => {

                                        const newValue = {
                                            label: value.label,
                                            type: value.type,
                                            extra: value.extra,
                                            value: values[value.slug]
                                        }

                                        fields[value.slug] = newValue
                                    })

                                    const draftForm = JSON.parse(JSON.stringify(this.props.formData))
                                    draftForm.fields = fields

                                    onSubmit(draftForm)
                                },
                                onCancel() { },
                            });
                        }}
                    >
                        Next step
                            </Button>

                </div>

            </div>
        )
    }
}



const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(DynamicFormGenerator);