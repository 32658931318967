import * as THEME from './theme'

export const TYPES_OF_FORMS = {
    MANPOWER: 'manpower',
    COMMISION: 'commission-requisition',
    LEAVE: 'leave',
    TRAINING: 'training',
    CLAIM: 'claim',
    CREDIT: 'credit-limit-request',
    LOAN: 'loanrentdemo',
    PURCHASE: 'purchase',

}
export const FIELD_TYPES = {
    text: 'text',
    float: 'float',
    dropdown: 'dropdown',
    textinput: 'textinput',
    textarea: 'textarea',
    boolean: 'boolean',
    date: 'date',
    integer: 'integer',
    file: 'file',
    hidden: 'hidden',
}

export {
    THEME,
}