
import React, { Component } from 'react';
import {
    Table,
    Input,
    PageHeader,
    Select,
    Form,
    InputNumber,
    DatePicker,
    Switch,
    Upload,
    Button,
    Skeleton,
    Layout,
    Pagination
} from 'antd';
// import { moveToPage } from "../../navigation/navigationService";
import { connect } from "react-redux";
// import moment from 'moment';
import { getPendingForActions, getPastSubmissions } from '../../services/api';
import { dashboardPastSubmission } from '../../services/api';
import * as navigationService from '../../navigation/navigationService';
import DynamicFormGenerator from '../../components/DynamicFormGenerator';
import { FSx } from 'aws-sdk';


const formating = (items) => {
    const moment = require('moment');
    let D = moment(items);
    D = D.format('LLL');
    return D;
}

const submitting = (items) => {
    const fullName = items.first_name + " " + items.last_name;
    return fullName;
}



class HomePage extends Component {

    state = {
        pendings: undefined,
        pastSubmissions: undefined,
    }

    componentDidMount = async () => {

        let pendings = await getPendingForActions()

        pendings = pendings.results && pendings.results.map(items => {
            const fd = {};
            fd.serial_number = items.serial_number;
            fd.id = items.id;
            fd.form = items.form;
            fd.created = formating(items.created);
            fd.modified = formating(items.modified);
            fd.submitted_for = submitting(items.submitted_for);
            fd.submitted_by = submitting(items.submitted_by);
            fd.status = (items.status);
            fd.key = items.id

            return fd;
        });

        let pastSubmissions = await dashboardPastSubmission()

        pastSubmissions = pastSubmissions.results && pastSubmissions.results

        this.setState({
            pendings: pendings,
            pastSubmissions
        })


        // .then(user=>({
        //     id:'user.id',
        //     form:'user.form',
        //     created:'user.created',
        //     modified:'user.modified',
        //     submitted_for:'user.submitted_for.first_name'+'user.submitted_for.last_name'
        // }))
        // console.log(form)
        // this.setState({ pendings: form })
        //console.log("form data: ", this.state.pendings);
    }

    render() {

        const pendingColumns = [
            {
                title: 'REFERENCE NO.',
                dataIndex: 'serial_number',
                key: 'serial_number',
            },
            {
                title: 'TYPE',
                dataIndex: 'form',
                key: 'form',
            },
            {
                title: 'DATE SUBMITTED',
                dataIndex: 'created',
                key: 'created',
            },
            {
                title: 'LAST UPDATED',
                dataIndex: 'modified',
                key: 'modified',
            },
            {
                title: 'SUBMITTED BY',
                dataIndex: 'submitted_by',
                key: 'submitted_by',
            },
            {
                title: 'SUBMITTED FOR',
                dataIndex: 'submitted_for',
                key: 'submitted_for',
            },
            {
                title: 'STATUS',
                dataIndex: 'status',
                key: 'status',
            },
            {
                title: '',
                dataIndex: 'view',
                key: 'view',
                render: (a, b) => {
                    console.log(b.key);
                    // let x="/draft/viewdraft/"+b.form+"/"+b.key+"/";
                    //return <a href={x}>View Form</a>
                    return (
                        <Button type="link" onClick={e => {
                            console.log(b)
                            const newParam = b;
                            this.props.dispatch(navigationService.moveToPage("/form/entries/action/manpower/307/", newParam))
                        }}>View Form</Button>
                    )
                }
            },
        ]

        const pastSubmissionColumns = [
            {
                title: 'REFERENCE NO.',
                dataIndex: 'serial_number',
                key: 'serial_number',
            },
            {
                title: 'TYPE',
                dataIndex: 'form',
                key: 'form',
            },
            {
                title: 'DATE SUBMITTED',
                dataIndex: 'created',
                key: 'created',
            },
            {
                title: 'LAST UPDATED',
                dataIndex: 'modified',
                key: 'modified',
            },
            {
                title: 'SUBMITTED BY',
                dataIndex: 'submitted_by',
                key: 'submitted_by',
            },
            {
                title: 'SUBMITTED FOR',
                dataIndex: 'submitted_for',
                key: 'submitted_for',
            },
            {
                title: 'STATUS',
                dataIndex: 'status',
                key: 'status',
            },
        ]


        console.log(this.state);

        return (
            <div>
                <PageHeader title="Home" />
                {
                    this.state.pendings && (
                        <div
                            // style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                            style={{textAlign: 'left'}}
                        >
                            <h3>Pending For Action</h3>
                            <Table 
                                columns={pendingColumns} 
                                dataSource={this.state.pendings} 
                                pagination={{ pageSize: 5 }}
                                scroll={{ x: pendingColumns.length * 100 }}
                            />
                        </div>
                    ) ||
                    (
                        <div
                            // style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                        >
                            <h1>Loading...</h1>
                            <Skeleton active />
                        </div>
                    )
                }
                {
                    this.state.pastSubmissions && (
                        <div
                            style={{textAlign: 'left'}}
                        >
                            <h3>Past Submissions</h3>
                            <Table 
                                columns={pastSubmissionColumns} 
                                dataSource={this.state.pastSubmissions} 
                                pagination={{ pageSize: 5 }}
                                scroll={{ x: pastSubmissionColumns.length * 100 }}
                            />
                        </div>
                    ) ||
                    (
                        <div>
                            <h1>Loading...</h1>
                            <Skeleton active />
                        </div>
                    )
                }
            </div>
        )

    }
}



const mapStateToProps = (state) => ({
    // for future if needed
})

export default connect(mapStateToProps)(HomePage);