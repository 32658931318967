import React, { Component } from 'react';

import {
    PageHeader,
    Button,
    Space
} from 'antd';

import {
    UsergroupAddOutlined,
    CalculatorOutlined,
    DollarOutlined,
    EuroOutlined,
    HistoryOutlined,
    IdcardOutlined,
    ShoppingOutlined,
} from '@ant-design/icons';

import * as navigationService from "../../navigation/navigationService";
import { connect } from "react-redux";
import "./SubmitNew.css";
import { Images } from "../../images/Images";

class SubmitNew extends Component {

    render() {

        return (

            <div>

                <PageHeader title="Submit New" />

                <div className="SubmitNew-Main-Con">

                    <div className="Buttons-Row-1">

                        <Space size='large'>

                            <Button
                                className="formButtons"
                                onClick={e => this.props.dispatch(navigationService.moveToPage("/submit-new/manpower"))}
                            >
                                <img src={Images.manpower} width="150" height="150" />
                            </Button>

                            <Button
                                className="formButtons"
                                onClick={e => this.props.dispatch(navigationService.moveToPage("/submit-new/claim"))}
                            >
                                <img src={Images.claim} width="150" height="150" />
                            </Button>

                            <Button
                                className="formButtons"
                                onClick={e => this.props.dispatch(navigationService.moveToPage("/submit-new/credit-limit-request"))}
                            >
                                <img src={Images.credit} width="150" height="150" />
                            </Button>

                            <Button
                                className="formButtons"
                                onClick={e => this.props.dispatch(navigationService.moveToPage("/submit-new/leave"))}
                            >
                                <img src={Images.replacement_leave} width="150" height="150" />
                            </Button>

                        </Space>
                    </div>

                    <div className="Buttons-Row-2">

                        <Space size='large'>

                            <Button
                                className="formButtons"
                                onClick={e => this.props.dispatch(navigationService.moveToPage("/submit-new/training"))}
                            >
                                <img src={Images.training} width="150" height="150" />
                            </Button>

                            <Button
                                className="formButtons"
                                onClick={e => this.props.dispatch(navigationService.moveToPage("/submit-new/purchase"))}
                            >
                                <img src={Images.purchase} width="150" height="150" />
                            </Button>

                            <Button
                                icon={<DollarOutlined />}
                                size="large"
                                className="formButtons"
                                onClick={e => this.props.dispatch(navigationService.moveToPage("/submit-new/loanrentdemo"))}
                            >
                                Loan
                            </Button>
                            {/* <Button 
                                className="formButtons"
                                onClick={e => this.props.dispatch(navigationService.moveToPage("/submit-new/loanrentdemo"))}
                            >
                                <img src={Images.manpower} width="150" height="150" />
                            </Button> */}
                        </Space>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    // for future if needed
})

export default connect(mapStateToProps)(SubmitNew);